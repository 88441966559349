import React, { createContext, useState, ReactNode, useEffect, useContext } from 'react'
import { authApi } from 'src/services/authApi'
import { userApi } from 'src/services/userApi'
import AuthContext from './JWTContext'

interface IUserLimitsContext {
    favCount: number
    maxFavCount: number
    wasteFav: () => void

    alertsCount: number
    maxAlertsCount: number
    wasteAlert: () => void

    fetchCount: number
    maxFetchCount: number
    wasteFetch: () => void
}

const initialUserLimitsContext: IUserLimitsContext = {
    favCount: 0,
    maxFavCount: 0,
    wasteFav: () => {},

    alertsCount: 0,
    maxAlertsCount: 0,
    wasteAlert: () => {},

    fetchCount: 0,
    maxFetchCount: 0,
    wasteFetch: () => {},
}

interface UserContextProviderProps {
    children: ReactNode
}

export const UserLimitsContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
    const layout = useContext(AuthContext)

    const [userLimitsContext, setUserLimitsContext] = useState<IUserLimitsContext>(initialUserLimitsContext)

    const getLimits = async () => {
        const [userData, statsData] = await Promise.all([authApi.me(), userApi.getStats()])

        setUserLimitsContext((prevContext) => ({
            ...prevContext,

            favCount: statsData.fav_count,
            maxFavCount: Number(userData.payment_plans[0].total_allowed_favorites),

            alertsCount: statsData.alerts_count,
            maxAlertsCount: Number(userData.payment_plans[0].total_allowed_alerts),

            fetchCount: statsData.pull_frequency,
            maxFetchCount: Number(userData.payment_plans[0].total_allowed_fetch),
        }))
    }

    useEffect(() => {
        if (layout.user) {
            getLimits()
        }
    }, [layout])

    const wasteFetch = () => {
        if (userLimitsContext.fetchCount < userLimitsContext.maxFetchCount) {
            setUserLimitsContext((prevContext) => ({
                ...prevContext,
                fetchCount: prevContext.fetchCount + 1,
            }))
        }
    }

    const wasteFav = () => {
        if (userLimitsContext.favCount < userLimitsContext.maxFavCount) {
            setUserLimitsContext((prevContext) => ({
                ...prevContext,
                favCount: prevContext.favCount + 1,
            }))
        }
    }

    const wasteAlert = () => {
        if (userLimitsContext.alertsCount < userLimitsContext.maxAlertsCount) {
            setUserLimitsContext((prevContext) => ({
                ...prevContext,
                alertsCount: prevContext.alertsCount + 1,
            }))
        }
    }

    return (
        <UserLimitsContext.Provider
            value={{
                ...userLimitsContext,
                wasteFav,
                wasteAlert,
                wasteFetch,
            }}
        >
            {children}
        </UserLimitsContext.Provider>
    )
}

export const UserLimitsContext = createContext<IUserLimitsContext>(initialUserLimitsContext)
