import ConstructionIcon from '@mui/icons-material/Construction'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'
import ListAltIcon from '@mui/icons-material/ListAlt'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import type { Theme } from '@mui/material'
import { Avatar, Box, Button, Drawer, Icon, Stack, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import type { FC, ReactNode, SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'
import { Img } from 'react-image'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import PATHS from 'src/common/constants/paths'
import useAuth from 'src/hooks/useAuth'
import DefaultAvatar from 'src/icons/DefaultAvatar'
import NavSection from '../../general/NavSection'
import Scrollbar from '../../general/Scrollbar'

interface DashboardSidebarProps {
    onMobileClose: () => void
    openMobile: boolean
    collapsed: boolean
    setCollapsed: React.Dispatch<SetStateAction<boolean>>
}

const defaultSections = [
    {
        title: '',
        items: [
            {
                icon: (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src="/static/icons/discord_white.svg" alt="" />
                    </Icon>
                ),
                title: 'Community',
                path: `${PATHS.DASHBOARD.COMMUNITY}`,
            },
            {
                icon: <NotificationsNoneIcon />,
                title: 'Alerts',
                path: `${PATHS.DASHBOARD.ALERTS}`,
            },
            {
                icon: <ConstructionIcon />,
                title: 'Extension',
                path: `${PATHS.DASHBOARD.EXTENSION}`,
            },
            {
                icon: <HelpOutlineIcon />,
                title: 'Support',
                path: `${PATHS.DASHBOARD.SUPPORT}`,
            },
        ],
    },
]

const sectionsUpcoming = [
    {
        title: '',
        items: [
            {
                icon: <ListAltIcon />,
                title: 'General',
                path: `${PATHS.DASHBOARD.ROOT}`,
                children: [
                    { title: 'Watch List', path: `${PATHS.DASHBOARD.UPCOMING.WATCHLIST}` },
                    { title: 'My Buying List', path: `${PATHS.DASHBOARD.UPCOMING.BUYING_LIST}` },
                ],
            },
        ],
    },
]

const sectionsTopSelling = [
    {
        title: '',
        items: [
            {
                icon: (
                    <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
                        <Img
                            style={{ width: '60%', height: '60%', alignSelf: 'center' }}
                            src="/static/logo.svg"
                            alt=""
                        />
                    </Stack>
                ),
                title: 'Home',
                path: `${PATHS.DASHBOARD.UPCOMING.ROOT}`,
            },
        ],
    },
]

const sectionsAnalytics = [
    {
        title: '',
        items: [
            {
                icon: <EventAvailableOutlinedIcon />,
                title: 'My Primary Favorites',
                path: `${PATHS.DASHBOARD.PRIMARY_FAVORITES}`,
                hover: 'Save and track your favorite events on the primary market. You can also perform and view actions such as drop checkers and low inventory alerts for your favorites.',
                tooltip: 'My Primary Favorites',
            },
            {
                icon: <EventOutlinedIcon />,
                title: 'My Secondary Favorites',
                path: `${PATHS.DASHBOARD.SECONDARY_FAVORITES}`,
                hover: 'Save and track your favorite events on the secondary market. You can also perform and view actions such as price drop alerts for your favorites.',
                tooltip: 'My Secondary Favorites',
            },
            {
                icon: <QueryStatsIcon />,
                title: 'Primary Top Movers',
                hover: 'View top moving events by the most tickets sold or highest percentage change on the primary market.',
                path: `${PATHS.DASHBOARD.PRIMARY_TOP_MOVERS}`,
                tooltip: 'Primary Top Movers',
            },
        ],
    },
]

const sectionsAdmin = [
    {
        title: '',
        items: [
            {
                icon: <ListAltIcon />,
                title: 'General',
                path: `${PATHS.DASHBOARD.ADMIN.ROOT}`,
                children: [
                    {
                        title: 'Subscription Management',
                        path: `${PATHS.DASHBOARD.ADMIN.SUBSCRIPTION_MANAGEMENT}`,
                        hover: 'Manage users subscription',
                    },
                    {
                        title: 'Tier Management',
                        path: `${PATHS.DASHBOARD.ADMIN.TIER_MANAGEMENT}`,
                        hover: 'Manage tiers',
                    },
                    {
                        title: 'Venue Match',
                        path: `${PATHS.DASHBOARD.ADMIN.VENUE_MATCH}`,
                        hover: 'Venue matching',
                    },
                    {
                        title: 'Event Match',
                        path: `${PATHS.DASHBOARD.ADMIN.EVENT_MATCH}`,
                        hover: 'Event matching',
                    },
                    {
                        title: 'Venue Mismatch',
                        path: `${PATHS.DASHBOARD.ADMIN.VENUE_MISMATCH}`,
                        hover: 'Venue mismatching',
                    },
                    { title: 'Seat Groups', path: `${PATHS.DASHBOARD.ADMIN.SEAT_GROUPS}`, hover: 'Seat groups' },
                    {
                        title: 'Add Venue Urls',
                        path: `${PATHS.DASHBOARD.ADMIN.VENUE_URLS}`,
                        hover: 'Add/remove venue urls',
                    },
                    {
                        title: 'Add Blacklist Words',
                        path: `${PATHS.DASHBOARD.ADMIN.BLACKLIST}`,
                        hover: 'Add/remove black list words',
                    },
                    {
                        title: 'Scrape Venue',
                        path: `${PATHS.DASHBOARD.ADMIN.SCRAPE_VENUE}`,
                        hover: 'Scrape a venue using venue id',
                    },
                    {
                        title: 'Hot Sections',
                        path: `${PATHS.DASHBOARD.ADMIN.HOT_SECTIONS}`,
                        hover: 'Find out hot sections',
                    },
                ],
            },
        ],
    },
]

interface dashboardSections {
    title: string
    items: {
        icon: ReactNode
        title: string
        path: string
        children?: { title: string; hover?: string; path: string }[]
    }[]
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
    const { onMobileClose, openMobile, collapsed, setCollapsed } = props
    const { user } = useAuth()
    const [sections, setSections] = useState<dashboardSections[]>([
        {
            title: '',
            items: [{ icon: '', title: '', path: '' }],
        },
    ])
    const [hoveredProfilePicture, setHoveredProfilePicture] = useState<boolean>()
    const location = useLocation()
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

    const isTopSelling = location.pathname.includes(`${PATHS.DASHBOARD.SECONDARY_TOP_SELLING}`)

    useEffect(() => {
        if (openMobile && onMobileClose) onMobileClose()

        if (location.pathname.includes(`${PATHS.DASHBOARD.UPCOMING.ROOT}`)) {
            setSections([...sectionsUpcoming, ...defaultSections])
        } else if (
            [
                PATHS.DASHBOARD.EVENT_ANALYTICS,
                PATHS.DASHBOARD.SECONDARY_ANALYTICS.split(':')[0],
                PATHS.DASHBOARD.PRIMARY_ANALYTICS.split(':')[0],
                PATHS.DASHBOARD.PRIMARY_FAVORITES,
                PATHS.DASHBOARD.SECONDARY_FAVORITES,
                PATHS.DASHBOARD.USER,
                PATHS.DASHBOARD.METRIC.ROOT,
                PATHS.DASHBOARD.PRIMARY_TOP_MOVERS,
            ].some((path) => location.pathname.includes(path))
        ) {
            setSections([
                ...(!user.payment_plans?.[0]?.hasAccessToTopMovers
                    ? [
                          ...sectionsAnalytics.map((section) => ({
                              ...section,
                              items: section.items.filter(
                                  (item) =>
                                      ![
                                          PATHS.DASHBOARD.PRIMARY_TOP_MOVERS,
                                          PATHS.DASHBOARD.SECONDARY_TOP_MOVERS,
                                      ].includes(item.path)
                              ),
                          })),
                      ]
                    : [...sectionsAnalytics]),
                ...defaultSections,
            ])
        } else if (location.pathname.includes(`${PATHS.DASHBOARD.ADMIN.ROOT}`)) {
            setSections([...sectionsAdmin, ...defaultSections])
        } else if (isTopSelling) {
            setSections([...sectionsTopSelling])
        } else {
            setSections([...sectionsUpcoming, ...defaultSections])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderTopRightRadius: isTopSelling ? '20px' : 'unset',
                zIndex: '1600',
                backgroundImage: 'linear-gradient(to bottom, #0C1D42, #061536)',
                width: collapsed ? 70 : 240,
                marginTop: {
                    xs: !isTopSelling ? '64px' : '0px',
                    lg: 'unset',
                },
            }}
        >
            <Scrollbar>
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {sections.map((section, index) => {
                        return (
                            <NavSection
                                key={index}
                                pathname={location.pathname + location.search}
                                collapsed={collapsed}
                                setCollapsed={setCollapsed}
                                {...section}
                            />
                        )
                    })}
                </Box>
            </Scrollbar>
            {lgUp && (
                <Box
                    sx={{
                        mx: 'auto',
                        pb: '5px',
                        pt: '15px',
                        width: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Button
                        onClick={() => setCollapsed(!collapsed)}
                        sx={{
                            backgroundColor: '#0D2459',
                            color: '#FFFFFF',
                            ...(collapsed && { width: '50%' }),
                        }}
                    >
                        {!collapsed ? (
                            <KeyboardDoubleArrowLeftOutlinedIcon />
                        ) : (
                            <KeyboardDoubleArrowRightOutlinedIcon />
                        )}
                    </Button>
                </Box>
            )}
            <Box
                component={RouterLink}
                to={`${PATHS.DASHBOARD.USER}`}
                sx={{
                    flex: '1',
                    display: 'flex',
                    px: '8px',
                    alignItems: 'center',
                    gap: '1rem',
                    borderTop: '2px solid #475467',
                    py: '14px',
                    textDecoration: 'none',
                    ...(collapsed && { justifyContent: 'center' }),
                    '&:hover': { bgcolor: '#0D2459' },
                    position: 'relative',
                }}
                onMouseEnter={() => setHoveredProfilePicture(true)}
                onMouseLeave={() => setHoveredProfilePicture(false)}
            >
                <Box>
                    {user.avatar !== undefined && user.avatar !== '' ? (
                        <Avatar
                            src={user.avatar}
                            sx={{
                                height: 40,
                                width: 40,
                                position: 'relative',
                            }}
                        />
                    ) : (
                        <DefaultAvatar sx={{ width: 40, height: 40 }} />
                    )}
                </Box>
                {!collapsed && (
                    <Typography
                        component={'span'}
                        sx={{
                            fontSize: '13px',
                            fontWeight: '700',
                            color: 'primary.main',
                            display: 'inline',
                            wordBreak: 'break-all',
                        }}
                    >
                        {user?.username}
                    </Typography>
                )}
                <Box
                    sx={{
                        display: hoveredProfilePicture ? 'block' : 'none',
                        position: 'absolute',
                        bgcolor: 'rgba(30,30,30,.8)',
                        zIndex: '1600',
                        p: '10px',
                        top: '-50px',
                        borderRadius: '4px',
                        color: 'white',
                        fontSize: '10px',
                        fontWeight: '500',
                    }}
                >
                    User Profile
                </Box>
            </Box>
        </Box>
    )

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: '#F7F6F4',
                        height: `calc(100% - ${!isTopSelling ? '64px' : '0px'}) !important`,
                        top: `${!isTopSelling ? '64px' : '0px'} !Important`,
                        borderRight: 'unset !important',
                    },
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        )
    } else {
        return (
            <Drawer
                anchor="left"
                onClose={onMobileClose}
                open={openMobile}
                PaperProps={{ sx: { backgroundColor: 'background.paper', width: 240 } }}
                variant="temporary"
            >
                {content}
            </Drawer>
        )
    }
}

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
}

export default DashboardSidebar
