import { IUser } from 'src/types/local/user'
import axiosClient from 'src/shared/utils/axios'
import wait from '../shared/utils/wait'
// import { FrontEndError } from 'src/utils/error'

class AuthApi {
    async login({ username, password }): Promise<string> {
        await wait(500)

        try {
            const user = await axiosClient().post('auth/login', {
                username,
                password,
            })

            if (user) {
                return user.data.token
            }
        } catch (err) {
            throw new Error(err.response.data.message || 'Internal server error')
        }
    }

    async register({ username, password, city, country }): Promise<string> {
        await wait(1000)

        try {
            let { data: user } = await axiosClient().post('auth/register', {
                username,
                password,
                city,
                country,
            })

            if (user) {
                return user.message as string
            }
        } catch (err) {
            throw new Error(err.response.data.message || 'Internal server error')
        }
    }

    async me(): Promise<IUser> {
        return new Promise(async (resolve, reject) => {
            try {
                let user = await axiosClient().get('api/user')
                resolve({
                    _id: user.data._id.$oid,
                    username: user.data.username,
                    firstname: user.data.firstname,
                    lastname: user.data.lastname,
                    company: user.data.company,
                    DOB: user.data.DOB,
                    country: user.data.country,
                    city: user.data.city,
                    street_address: user.data.street_address,
                    unit: user.data.unit,
                    zipcode: user.data.zipcode,
                    phoneNumber: user.data.phoneNumber,
                    isAdmin: user.data.isAdmin,
                    payment_plans: user.data.payment_plans,
                    fetch_count: user.data.fetch_count,
                    discordWebhook: user.data.discordWebhook,
                    permissions: user.data.permissions,
                    notFirstSeen: user.data.notFirstSeen,
                    // TODO: remove this after launch tier update
                    notFirstSeenTierUpdate: user.data.notFirstSeenTierUpdate,
                    plans: user.data.plans,
                    parent_id: user.data.parent_id,
                })
            } catch (err) {
                reject(new Error(err.response.data.message || 'Internal server error'))
            }
        })
    }

    async forgotPassword({ email }): Promise<any> {
        try {
            await axiosClient().post('/api/user/password/forgot', {
                email,
            })
        } catch (err) {
            throw new Error(err.message || 'Internal server error')
        }
    }

    async resetPassword({ new_password, new_password_confirmation }): Promise<any> {
        try {
            const urlParams = new URLSearchParams(window.location.search)
            const token = urlParams.get('token')
            await axiosClient().post('/api/user/password/reset', {
                token,
                new_password,
                new_password_confirmation,
            })
        } catch (err) {
            throw new Error(err?.response?.data?.message || err.message || 'Internal server error')
        }
    }

    async resendVerifyEmail(email: string) {
        try {
            await axiosClient().post('auth/resend-verify-email', {
                email,
            })
        } catch (err) {
            console.log(err)
            if (err.response?.status === 401) {
                throw new Error(err.response.data.message)
            }
            throw new Error(err.message || 'Internal server error')
        }
    }

    async verifyCode(token: string) {
        try {
            const response = await axiosClient().post('/auth/verify-code', {
                token: `${token}`,
            })
            if (response.data.token) {
                return response.data.token
            }
        } catch (err) {
            throw new Error(err.message || 'Internal server error')
        }
    }
}

export const authApi = new AuthApi()
